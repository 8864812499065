<template>
  <main>
    <PageTitleContainer>
      <PageTitle removeMargin>Aggiungi vino</PageTitle>

      <div class="mb-3 text-right">
        <router-link class="btn btn-outline-secondary" :to="{ name: 'inventory.wines' }">Torna indietro</router-link>
      </div>
    </PageTitleContainer>

    <div class="container">
      <WineForm :value="wine" :feedback.sync="feedback" @submitForm="saveWine" />
    </div>
  </main>
</template>

<script>

import PageTitle from '@/views/components/Private/PageTitle.vue';
import PageTitleContainer from '@/views/components/Private/PageTitleContainer.vue';
import WineForm from '@/views/components/Form/WineForm.vue';

export default {
  components: {
    PageTitle,
    PageTitleContainer,
    WineForm,
  },
  data () {
    return {
      wine: undefined,
      feedback: null,
    };
  },
  methods: {
    success () {
      this.feedback = true;
      this.$router.replace({ name: 'inventory.wines' });
    },
    failed (err) {
      this.feedback = false;
      this.$log.error(err);
    },
    saveWine (wineForm) {
      const wine = { ...wineForm };

      this.$api.createWine(wine)
        .then(() => this.success())
        .catch(err => this.failed(err))
      ;
    },
  },
};

</script>
